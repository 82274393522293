import {Box} from '@primer/react'
import styles from './IssueTypePickerItemLeadingVisual.module.css'
import {colorNames, useNamedColor} from '@github-ui/use-named-color'
import type {IssueTypeColor} from './__generated__/IssueTypePickerIssueType.graphql'

export const createIssueTypePickerItemLeadingVisual = (color: IssueTypeColor) =>
  function IssueTypePickerItemLeadingVisual() {
    const effectiveColor = colorNames.find(c => c === color)
    const {fg, bg, border} = useNamedColor(effectiveColor || 'GRAY')
    return <Box sx={{backgroundColor: bg, color: fg, borderColor: border}} className={styles.leadingVisual} />
  }

try{ IssueTypePickerItemLeadingVisual.displayName ||= 'IssueTypePickerItemLeadingVisual' } catch {}