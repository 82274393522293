export enum DisplayMode {
  TemplatePicker,
  IssueCreation,
}

type getDefaultDisplayModeParams = {
  repositoryHasIssuesEnabled: boolean
  hasSelectedTemplate: boolean
  hasAvailableTemplates: boolean
  onChoosePage?: boolean
}

export function getDefaultDisplayMode({
  repositoryHasIssuesEnabled,
  hasSelectedTemplate,
  hasAvailableTemplates,
  onChoosePage = false,
}: getDefaultDisplayModeParams) {
  if (!repositoryHasIssuesEnabled || onChoosePage) {
    // Template picker will show an error message for repositories without issues, therefore show this.
    // Otherwise, if we're on the /choose page, we should show the template picker always.
    return DisplayMode.TemplatePicker
  }

  // If we have a selected template or no available templates, we should go straight to issue creation.
  if (hasSelectedTemplate || !hasAvailableTemplates) {
    return DisplayMode.IssueCreation
  }

  return DisplayMode.TemplatePicker
}
