export const TEST_IDS = {
  assignees: 'issue-assignees',
  // commentHeaderHamburger: 'comment-header-hamburger',
  // commentHeaderHamburgerOpen: 'comment-header-hamburger-open',
  // commentViewerOuterBox: (id: string | undefined) => {
  //   return id ? `comment-viewer-outer-box-${id}` : 'comment-viewer-outer-box'
  // },
  // commentComposer: 'comment-composer',
  // issueViewerIssueContainer: 'issue-viewer-issue-container',
  // issueViewerCommentsContainer: 'issue-viewer-comments-container',
  // issueViewerMetadataContainer: 'issue-viewer-metadata-container',
  // issueTitleInput: 'issue-title-input',
  // issueBody: 'issue-body',
  issueLabels: 'issue-labels',
  // markdownBody: 'markdown-body',
  // commentBox: (postFix = '') => `markdown-editor-${postFix}`,
  // issueHeader: 'issue-header',
  // issueTitle: 'issue-title',
  // editIssueTitleButton: 'edit-issue-title-button',
  // editIssueBodyButton: 'edit-issue-body-button',
  // issueTimeline: (type: string) => `issue-timeline-${type}`,
  // highlightedTimeline: 'highlighted-timeline',
  // highlightedTimelineLoading: 'highlighted-timeline-loading',
  // commentSkeleton: 'comment-skeleton',
  // linkedPullRequestContainer: 'issue-viewer-linked-pr-container',
  // headerState: 'header-state',
  milestoneContainer: 'issue-milestone-container',
  milestoneProgressIcon: 'issue-milestone-progress-bar',
  // projectsContainer: 'sidebar-projects-section',
}
