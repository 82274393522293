import {useKeyPress} from '@github-ui/use-key-press'
import {useRef, useState, type RefObject} from 'react'

type LazyItemPickerProps = {
  hotkey?: string
  anchorElement: (props: React.HTMLAttributes<HTMLElement>, ref: RefObject<HTMLButtonElement>) => JSX.Element
  createChild: () => JSX.Element
  insidePortal?: boolean
}

export function LazyItemPicker({anchorElement, createChild, hotkey, insidePortal}: LazyItemPickerProps): JSX.Element {
  const [wasTriggered, setWasTriggered] = useState(false)
  const anchorRef = useRef<HTMLButtonElement>(null)

  useKeyPress(
    hotkey ? [hotkey] : [],
    (e: KeyboardEvent) => {
      if (!wasTriggered) {
        setWasTriggered(true)
        e.preventDefault()
      }
    },
    {triggerWhenPortalIsActive: insidePortal},
  )

  if (!wasTriggered) {
    return anchorElement({onClick: () => setWasTriggered(true)}, anchorRef)
  }

  return createChild()
}

try{ LazyItemPicker.displayName ||= 'LazyItemPicker' } catch {}