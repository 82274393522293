import {TextInput} from '@primer/react'
import {type ChangeEvent, type KeyboardEvent, useCallback, useRef, useState} from 'react'
import {graphql, useFragment, useRelayEnvironment} from 'react-relay'

import {commitUpdateProjectItemFieldValueMutation} from '../../../mutations/update-project-item-field-value'
import type {TextFieldConfigFragment$key} from './__generated__/TextFieldConfigFragment.graphql'
import type {TextFieldFragment$key} from './__generated__/TextFieldFragment.graphql'
import {FieldWrapper} from './FieldWrapper'
import type {BaseFieldProps} from './Shared'

const TextFieldFragment = graphql`
  fragment TextFieldFragment on ProjectV2ItemFieldTextValue {
    id
    text
    field {
      ...TextFieldConfigFragment
    }
  }
`

const TextFieldConfigFragment = graphql`
  fragment TextFieldConfigFragment on ProjectV2Field {
    id
    name
  }
`

type TextFieldProps = BaseFieldProps<TextFieldConfigFragment$key, TextFieldFragment$key>

export function TextField({viewerCanUpdate, itemId, projectId, field, value, onIssueUpdate}: TextFieldProps) {
  const environment = useRelayEnvironment()

  const inputRef = useRef<HTMLInputElement>(null)

  const fieldData = useFragment(TextFieldConfigFragment, field)
  const valueData = useFragment(TextFieldFragment, value)

  const fieldId = fieldData.id
  const fieldName = fieldData.name

  const [showInput, setShowInput] = useState(false)

  const [currentValue, setCurrentValue] = useState(valueData?.text ?? '')

  const commitCurrentValue = useCallback(() => {
    commitUpdateProjectItemFieldValueMutation({
      environment,
      input: {
        fieldId,
        itemId,
        projectId,
        value: {text: currentValue},
      },
      onCompleted: onIssueUpdate,
    })
  }, [environment, fieldId, itemId, projectId, currentValue, onIssueUpdate])

  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setCurrentValue(e.target.value)
  }, [])

  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      // eslint-disable-next-line @github-ui/ui-commands/no-manual-shortcut-logic
      if (e.key === 'Enter') {
        commitCurrentValue()
        setShowInput(false)
      }
      // eslint-disable-next-line @github-ui/ui-commands/no-manual-shortcut-logic
      if (e.key === 'Escape') {
        e.stopPropagation()
        if (inputRef.current) {
          setCurrentValue(valueData?.text ?? '')
          // eslint-disable-next-line github/no-blur
          inputRef.current.blur()
          setShowInput(false)
        }
      }
    },
    [commitCurrentValue, valueData?.text],
  )

  if (!viewerCanUpdate)
    return (
      <FieldWrapper
        showInput={showInput}
        setShowInput={setShowInput}
        placeholder={`No ${fieldName}`}
        canUpdate={false}
        value={currentValue}
        name={fieldName}
      />
    )

  return (
    <FieldWrapper
      value={currentValue}
      name={fieldName}
      placeholder="Enter text…"
      inputRef={inputRef}
      showInput={showInput}
      setShowInput={setShowInput}
      onCommit={commitCurrentValue}
      input={
        <TextInput
          ref={inputRef}
          size="small"
          sx={{fontSize: 0}}
          onChange={onChange}
          onKeyDown={onKeyDown}
          value={currentValue}
        />
      }
    />
  )
}

try{ TextField.displayName ||= 'TextField' } catch {}