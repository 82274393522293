import type {RepositoryPickerRepository$data as Repository} from '@github-ui/item-picker/RepositoryPickerRepository.graphql'
import type {RepositoryPickerRepositoryIssueTemplates$data as Templates} from '@github-ui/item-picker/RepositoryPickerRepositoryIssueTemplates.graphql'
import {Box, Label, Link} from '@primer/react'
import {LABELS} from './constants/labels'
import {useIssueCreateConfigContext} from './contexts/IssueCreateConfigContext'
import {repoHasAvailableTemplates} from './utils/model'

type TemplateListPaneFooterProps = {
  repository: Repository
  templates: Templates
}

export const TemplateListPaneFooter = ({repository, templates}: TemplateListPaneFooterProps) => {
  const {optionConfig} = useIssueCreateConfigContext()
  const canIssueType =
    repository.viewerIssueCreationPermissions.typeable && repository.viewerIssueCreationPermissions.triageable

  if (!repoHasAvailableTemplates(templates)) {
    return null
  }

  const hyperlinkText = repository.viewerCanPush ? LABELS.editTemplates : LABELS.viewTemplates

  const fullscreenStyling = {
    alignItems: 'flex-start',
    textAlign: 'left',
    pt: 3,
  }

  return (
    <Box sx={{...(!optionConfig.insidePortal && {...fullscreenStyling})}}>
      <>
        {canIssueType && (
          <>
            <Label variant="success" sx={{mr: 2}}>
              Beta
            </Label>
            <span>🎉 You can now add issue types to your forms and templates! </span>
          </>
        )}
        <Link href={templates.templateTreeUrl} sx={{whiteSpace: 'nowrap'}}>
          {hyperlinkText}
        </Link>
      </>
    </Box>
  )
}

try{ TemplateListPaneFooter.displayName ||= 'TemplateListPaneFooter' } catch {}