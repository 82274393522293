import {noop} from '@github-ui/noop'
import type {ValidationResult} from '@github-ui/entity-validators'
import {Box, Flash, useRefObjectAsForwardedRef} from '@primer/react'
import {MarkdownEditor, type MarkdownEditorHandle, type MarkdownEditorProps} from '@github-ui/markdown-editor'

import {useMarkdownSuggestions} from './util/use-markdown-suggestions'
import {
  type ForwardedRef,
  forwardRef,
  type ReactElement,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import {emojiList} from './constants/emojis'
import strings from './constants/strings'
import {SlashCommandsButton, SlashCommandsProvider} from './components/SlashCommandsProvider'
import {AddTasklistButton} from './components/AddTaskListButton'
import {acceptedFileTypes, useUploadFile} from './api/file-upload'
import type {Subject as CommentEditorSubject} from './subject'
import {useFeatureFlag} from '@github-ui/react-core/use-feature-flag'
import {useGetPreview} from './api/preview'
import {ApiMarkdownSubject} from './api/types'
import {SaveButton} from './components/SaveButton'
import type {TestIdProps} from '@github-ui/test-id-props'
import {AddSuggestionButton} from './AddSuggestionButton'

export interface CommentEditorConfig {
  pasteUrlsAsPlainText: boolean
  useMonospaceFont: boolean
}

export type ViewMode = 'preview' | 'edit'

export type CommentEditorHandle = MarkdownEditorHandle

export const CommentEditorButton = MarkdownEditor.ActionButton

export interface CommentEditorProps
  extends TestIdProps,
    Omit<
      MarkdownEditorProps,
      | 'emojiSuggestions'
      | 'mentionSuggestions'
      | 'referenceSuggestions'
      | 'onUploadFile'
      | 'acceptedFileTypes'
      | 'savedReplies'
      | 'onRenderPreview'
      | 'children'
    > {
  label?: string
  showLabel?: boolean
  subject?: CommentEditorSubject
  onSave?: () => void
  onCancel?: () => void
  validationResult?: ValidationResult
  saveButtonText?: string
  saveButtonTrailingIcon?: boolean
  actions?: React.ReactNode
  userSettings?: CommentEditorConfig
  /** Block saving due to stale content. */
  contentIsStale?: boolean
  fileUploadsEnabled?: boolean
  buttonSize?: 'small' | 'medium'
  suggestedChangesConfig?: {
    showSuggestChangesButton: boolean
    suggestedChangeLines: string | undefined
    onInsertSuggestedChange: () => void
    shouldInsertSuggestedChange?: boolean
  }
}

export const CommentEditor = forwardRef(
  (
    {
      ['aria-describedby']: ariaDescribedby,
      ['data-testid']: testId,
      label,
      labelledBy,
      showLabel,
      disabled = false,
      subject,
      viewMode,
      onChangeViewMode,
      value,
      placeholder,
      onChange,
      onSave,
      onCancel,
      validationResult,
      saveButtonText = 'Save',
      saveButtonTrailingIcon = true,
      actions,
      userSettings: optionConfig,
      contentIsStale,
      fileUploadsEnabled = true,
      minHeightLines,
      maxHeightLines,
      sx,
      onPrimaryAction,
      buttonSize,
      suggestedChangesConfig,
    }: CommentEditorProps,
    forwardedRef: ForwardedRef<CommentEditorHandle>,
  ): ReactElement => {
    const apiSubject: Partial<ApiMarkdownSubject> = ApiMarkdownSubject.fromPropsSubject(subject)
    const projectId = subject?.type === 'project' ? subject?.id?.databaseId.toString() : undefined

    const {mentions, references, savedReplies} = useMarkdownSuggestions(apiSubject)
    const renderPreview = useGetPreview(apiSubject)
    const uploadFile = useUploadFile(apiSubject.subjectRepoId?.toString(), projectId)

    const [currentViewMode, setCurrentViewMode] = useState<ViewMode>(viewMode ?? 'edit')
    const [showContentIsStaleWarning, setShowContentIsStaleWarning] = useState(false)

    const ref = useRef<MarkdownEditorHandle>(null)
    useRefObjectAsForwardedRef(forwardedRef, ref)

    const warningRef = useRef<HTMLDivElement | null>(null)

    const isRepoSubject = subject !== undefined && 'repository' in subject && subject.repository !== undefined
    const isRepoSlashCommandEnabled = isRepoSubject && subject.repository.slashCommandsEnabled

    const showAddTaskListButton = useFeatureFlag('tasklist_block') && isRepoSubject

    const [shouldInsertSuggestedChangeOnRender, setShouldInsertSuggestedChangeOnRender] = useState<boolean>(
      suggestedChangesConfig?.shouldInsertSuggestedChange ?? false,
    )

    useEffect(() => {
      setShowContentIsStaleWarning(contentIsStale ?? false)
      return () => {
        setShowContentIsStaleWarning(false)
      }
    }, [contentIsStale])

    const onSaveAction = useMemo(() => {
      if (showContentIsStaleWarning) {
        // This effectively disables/hides the save button
        return noop
      }
      return () => {
        onSave?.()
        setCurrentViewMode('edit')
      }
    }, [onSave, showContentIsStaleWarning])

    useEffect(() => {
      setCurrentViewMode('edit')
    }, [apiSubject.subjectRepoId, apiSubject.subjectId])

    useEffect(() => {
      if (viewMode) {
        setCurrentViewMode(viewMode)
      }
    }, [viewMode])

    const onChangeViewModeInternal = useCallback(
      (v: ViewMode) => {
        setCurrentViewMode(v)
        onChangeViewMode?.(v)
      },
      [onChangeViewMode],
    )

    const onInsertText = useCallback(
      (text: string) => {
        ref.current?.focus()
        try {
          document.execCommand('insertText', false, text)
        } catch {
          // this is a pretty naive approach and may fail in some environments (particularly JSDom)
          // but it will work in most cases and it's not worth reimplementing the full robust effort
          // used by the MarkdownEditor internals
        }
      },
      [ref],
    )

    // Only scroll to the warning banner if otherwise the user would not be able to see it
    const warningBannerPosition = 173
    // eslint-disable-next-line ssr-friendly/no-dom-globals-in-react-fc
    if (showContentIsStaleWarning && warningRef.current !== null && window.scrollY > warningBannerPosition) {
      warningRef.current.scrollIntoView()
    }

    // Suspense is here to avoid suspending the broader component tree when being rendered on the server with SSR
    // this is a workaround for https://github.com/github/primer/issues/2090
    const editor = (
      <Suspense>
        {showContentIsStaleWarning && (
          <Flash ref={warningRef} sx={{mb: 2}} variant="danger">
            {strings.staleContentError}
          </Flash>
        )}
        <MarkdownEditor
          aria-describedby={ariaDescribedby}
          labelledBy={labelledBy}
          disabled={disabled}
          value={value}
          placeholder={placeholder !== undefined ? placeholder : strings.leaveCommentLabel}
          emojiSuggestions={emojiList}
          mentionSuggestions={mentions}
          referenceSuggestions={references}
          viewMode={currentViewMode}
          onChangeViewMode={onChangeViewModeInternal}
          onChange={onChange}
          onRenderPreview={renderPreview}
          onUploadFile={fileUploadsEnabled ? uploadFile : undefined}
          acceptedFileTypes={fileUploadsEnabled ? acceptedFileTypes(isRepoSubject) : undefined}
          onPrimaryAction={() => {
            onSaveAction()
            onPrimaryAction?.()
          }}
          ref={ref}
          pasteUrlsAsPlainText={optionConfig?.pasteUrlsAsPlainText || false}
          savedReplies={savedReplies}
          monospace={optionConfig?.useMonospaceFont || false}
          minHeightLines={minHeightLines}
          maxHeightLines={maxHeightLines}
          sx={sx}
        >
          <MarkdownEditor.Label visuallyHidden={!showLabel}>{label ?? 'Markdown Editor'}</MarkdownEditor.Label>
          <MarkdownEditor.Actions>
            {actions}
            {onCancel && (
              <MarkdownEditor.ActionButton variant="default" size={buttonSize} onClick={onCancel} value="Cancel">
                Cancel
              </MarkdownEditor.ActionButton>
            )}
            {onSaveAction && validationResult && (
              <SaveButton
                onSave={onSaveAction}
                validationResult={validationResult}
                trailingIcon={saveButtonTrailingIcon}
                disabled={disabled}
                size={buttonSize}
              >
                {saveButtonText}
              </SaveButton>
            )}
          </MarkdownEditor.Actions>
          <MarkdownEditor.Toolbar>
            {suggestedChangesConfig?.showSuggestChangesButton && (
              <AddSuggestionButton
                shouldInsertSuggestionOnRender={shouldInsertSuggestedChangeOnRender}
                inputValue={value}
                onChange={onChange}
                suggestedChangeLines={suggestedChangesConfig?.suggestedChangeLines}
                onInsertSuggestedChange={() => {
                  suggestedChangesConfig?.onInsertSuggestedChange()
                  setShouldInsertSuggestedChangeOnRender(false)
                }}
              />
            )}
            <MarkdownEditor.DefaultToolbarButtons />
            <SlashCommandsButton />
            {showAddTaskListButton && <AddTasklistButton editedBody={value} onChangeBody={onChange} />}
          </MarkdownEditor.Toolbar>
        </MarkdownEditor>
      </Suspense>
    )

    return (
      <Box sx={{mb: 0}} data-testid={testId}>
        {isRepoSlashCommandEnabled ? (
          <SlashCommandsProvider subject={subject} onInsertText={onInsertText} onSave={onSaveAction}>
            {editor}
          </SlashCommandsProvider>
        ) : (
          editor
        )}
      </Box>
    )
  },
)

CommentEditor.displayName = 'CommentEditor'
